import Topbar from "./scenes/global/Topbar"
import { PageHeader } from "./components/page-headers/page-headers";
import { ThemeProvider } from 'styled-components';
import config from './config';
import React, { useEffect, useState, lazy } from 'react';

const { themeColor } = config;




function ProviderConfig() {
  

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  const PageRoutes = [
    {
      path: 'index',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'first',
      breadcrumbName: 'Demo 1',
    },
  ];

  return (
       <ThemeProvider theme={{ ...themeColor }}>
          
        
          <div className="mobile-search">
          <form className="search-form">
          <img src="img/svg/search.svg" alt="search" className="svg" />
          <input className="form-control me-sm-2 box-shadow-none" type="search" placeholder="Search..." aria-label="Search" />
          </form>
          </div>
             {/* <PageHeader className="ninjadash-page-header-main" title="Dashboard" routes={PageRoutes} /> */}

             <>
  <div className="mobile-author-actions" />
  <header className="header-top">
    <nav className="navbar navbar-light">
      <div className="navbar-left">
        <div className="logo-area">
          <a className="navbar-brand" href="index.html">
            <img className="dark" src="img/logo-dark.png" alt="logo" />
            <img className="light" src="img/logo-white.png" alt="logo" />
          </a>
          <a href="#" className="sidebar-toggle">
            <img className="svg" src="img/svg/align-center-alt.svg" alt="img" />
          </a>
        </div>
        <div className="top-menu">
          <div className="hexadash-top-menu position-relative">
            <ul>
              <li className="has-subMenu">
                <a href="#" className="">
                  Dashboard
                </a>
                <ul className="subMenu">
                  <li className="">
                    <a href="index.html">Demo 1</a>
                  </li>
                  <li className="">
                    <a href="demo2.html">Demo 2</a>
                  </li>
                  <li className="">
                    <a href="demo3.html">Demo 3</a>
                  </li>
                  <li className="">
                    <a href="demo4.html">Demo 4</a>
                  </li>
                  <li className="">
                    <a href="demo5.html">Demo 5</a>
                  </li>
                  <li className="">
                    <a href="demo6.html">Demo 6</a>
                  </li>
                  <li className="">
                    <a href="demo7.html">Demo 7</a>
                  </li>
                  <li className="">
                    <a href="demo8.html">Demo 8</a>
                  </li>
                  <li className="">
                    <a href="demo9.html">Demo 9</a>
                  </li>
                  <li className="">
                    <a href="demo10.html">Demo 10</a>
                  </li>
                </ul>
              </li>
              <li className="has-subMenu">
                <a href="#" className="">
                  Layouts
                </a>
                <ul className="subMenu">
                  <li className="l_sidebar">
                    <a href="#" data-layout="light">
                      Light Mode
                    </a>
                  </li>
                  <li className="l_sidebar">
                    <a href="#" data-layout="dark">
                      Dark Mode
                    </a>
                  </li>
                  <li className="l_navbar">
                    <a href="#" data-layout="side">
                      Side Menu
                    </a>
                  </li>
                  <li className="layout">
                    <a href="../rtl">RTL</a>
                  </li>
                  <li className="layout">
                    <a href="../ltr">LTR</a>
                  </li>
                </ul>
              </li>
              <li className="has-subMenu">
                <a href="#" className="">
                  Apps
                </a>
                <ul className="megaMenu-wrapper megaMenu-dropdown">
                  <li>
                    <ul>
                      <li className="has-subMenu-left">
                        <a href="#" className="">
                          <img
                            src="img/svg/mail.svg"
                            alt="mail"
                            className="svg nav-icon"
                          />
                          <span className="menu-text">Email</span>
                        </a>
                        <ul className="subMenu">
                          <li>
                            <a href="inbox.html" className="">
                              Inbox
                            </a>
                          </li>
                          <li>
                            <a href="read-email.html" className="">
                              Read Email
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="chat.html" className="">
                          <img
                            src="img/svg/message-square.svg"
                            alt="message-square"
                            className="svg nav-icon"
                          />
                          <span className="menu-text">Chat</span>
                          <span className="badge badge-success menuItem rounded-circle">
                            3
                          </span>
                        </a>
                      </li>
                      <li className="has-subMenu-left">
                        <a href="#" className="">
                          <img
                            src="img/svg/shopping-cart.svg"
                            alt="shopping-cart"
                            className="svg nav-icon"
                          />
                          <span className="menu-text text-initial">
                            eCommerce
                          </span>
                        </a>
                        <ul className="subMenu">
                          <li>
                            <a href="products.html" className="">
                              Products
                            </a>
                          </li>
                          <li>
                            <a href="product-details.html" className="">
                              Product Details
                            </a>
                          </li>
                          <li>
                            <a href="add-product.html" className="">
                              Product Add
                            </a>
                          </li>
                          <li>
                            <a href="" className="">
                              Product Edit
                            </a>
                          </li>
                          <li>
                            <a href="cart.html" className="">
                              Cart
                            </a>
                          </li>
                          <li>
                            <a href="order.html" className="">
                              Orders
                            </a>
                          </li>
                          <li>
                            <a href="sellers.html" className="">
                              Sellers
                            </a>
                          </li>
                          <li>
                            <a href="invoice.html" className="">
                              Invoices
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="has-subMenu-left">
                        <a href="#" className="">
                          <span className="nav-icon uil uil-folder" />
                          <span className="menu-text">Project</span>
                        </a>
                        <ul className="subMenu">
                          <li>
                            <a href="projects.html" className="">
                              Project
                            </a>
                          </li>
                          <li>
                            <a href="application-ui.html" className="">
                              Project Details
                            </a>
                          </li>
                          <li>
                            <a href="create.html" className="">
                              Create Project
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="calendar.html" className="">
                          <span className="nav-icon uil uil-calendar-alt" />
                          <span className="menu-text">Calendar</span>
                        </a>
                      </li>
                      <li className="has-subMenu-left">
                        <a href="#" className="">
                          <span className="nav-icon uil uil-users-alt" />
                          <span className="menu-text">Users</span>
                        </a>
                        <ul className="subMenu">
                          <li>
                            <a href="users-membar.html" className="">
                              Team
                            </a>
                          </li>
                          <li>
                            <a href="users-card.html" className="">
                              Users Grid
                            </a>
                          </li>
                          <li>
                            <a href="users-list.html" className="">
                              Users List
                            </a>
                          </li>
                          <li>
                            <a href="users-card2.html" className="">
                              Users Grid style
                            </a>
                          </li>
                          <li>
                            <a href="users-group.html" className="">
                              Users Group
                            </a>
                          </li>
                          <li>
                            <a href="user-info.html" className="">
                              Add User
                            </a>
                          </li>
                          <li>
                            <a href="users-datatable.html" className="">
                              Users Table
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="has-subMenu-left">
                        <a href="#" className="">
                          <img
                            src="img/svg/user-check.svg"
                            alt="user-check"
                            className="svg nav-icon"
                          />
                          <span className="menu-text">Contact</span>
                        </a>
                        <ul className="subMenu">
                          <li>
                            <a className="" href="contact-1.html">
                              Contact 1
                            </a>
                          </li>
                          <li>
                            <a className="" href="contact-2.html">
                              Contact 2
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="chat.html" className="">
                          <img
                            src="img/svg/bookmark.svg"
                            alt="bookmark"
                            className="svg nav-icon"
                          />
                          <span className="menu-text">Note</span>
                        </a>
                      </li>
                      <li>
                        <a href="chat.html" className="">
                          <img
                            src="img/svg/activity.svg"
                            alt="activity"
                            className="svg nav-icon"
                          />
                          <span className="menu-text">To-Do</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <a href="kanban.html" className="">
                          <img
                            src="img/svg/columns.svg"
                            alt="columns"
                            className="svg nav-icon"
                          />
                          <span className="menu-text">Kanban Board</span>
                        </a>
                      </li>
                      <li className="has-subMenu-left">
                        <a href="#" className="">
                          <img
                            src="img/svg/repeat.svg"
                            alt="repeat"
                            className="svg nav-icon"
                          />
                          <span className="menu-text">Import &amp; Export</span>
                        </a>
                        <ul className="subMenu">
                          <li>
                            <a className="" href="import.html">
                              Import
                            </a>
                          </li>
                          <li>
                            <a className="" href="export.html">
                              Export
                            </a>
                          </li>
                          <li>
                            <a className="" href="export-selected.html">
                              Export Selected
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="file-manager.html" className="">
                          <img
                            src="img/svg/file.svg"
                            alt="file"
                            className="svg nav-icon"
                          />
                          <span className="menu-text">File Manager</span>
                        </a>
                      </li>
                      <li>
                        <a href="task-app.html" className="">
                          <img
                            src="img/svg/clipboard.svg"
                            alt="clipboard"
                            className="svg nav-icon"
                          />
                          <span className="menu-text">Task App</span>
                        </a>
                      </li>
                      <li>
                        <a href="bookmarks.html" className="">
                          <span className="nav-icon uil uil-bookmark" />
                          <span className="menu-text">Bookmarks</span>
                        </a>
                      </li>
                      <li className="has-subMenu-left">
                        <a href="#" className="">
                          <img
                            src="img/svg/user.svg"
                            alt="user"
                            className="svg nav-icon"
                          />
                          <span className="menu-text">Social app</span>
                        </a>
                        <ul className="subMenu">
                          <li>
                            <a href="profile.html" className="">
                              My Profile
                            </a>
                          </li>
                          <li>
                            <a href="profile-setting.html" className="">
                              Profile Settings
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="has-subMenu-left">
                        <a href="#" className="">
                          <span className="nav-icon uil uil-user" />
                          <span className="menu-text">Support</span>
                        </a>
                        <ul className="subMenu">
                          <li>
                            <a className="" href="support-ticket.html">
                              Support Ticket
                            </a>
                          </li>
                          <li>
                            <a className="" href="support-details.html">
                              Ticket Details
                            </a>
                          </li>
                          <li>
                            <a className="" href="new-ticket.html">
                              New Ticket
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="has-subMenu-left">
                        <a href="#" className="">
                          <span className="nav-icon uil uil-search" />
                          <span className="menu-text">JobSearch</span>
                        </a>
                        <ul className="subMenu">
                          <li>
                            <a className="" href="jobSearch.html">
                              Grid view
                            </a>
                          </li>
                          <li>
                            <a className="" href="jobSearchList.html">
                              List View
                            </a>
                          </li>
                          <li>
                            <a className="" href="jobDetails.html">
                              Details
                            </a>
                          </li>
                          <li>
                            <a className="" href="job-apply.html">
                              Apply
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="has-subMenu-left">
                        <a href="#" className="">
                          <span className="nav-icon uil uil-table" />
                          <span className="menu-text">Table</span>
                        </a>
                        <ul className="subMenu">
                          <li>
                            <a href="basic-table.html" className="">
                              Basic Table
                            </a>
                          </li>
                          <li>
                            <a href="datatable.html" className="">
                              Data Table
                            </a>
                          </li>
                          <li>
                            <a href="dynamic-table.html" className="">
                              Dynamic Table
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="has-subMenu">
                <a href="#" className="">
                  Crud
                </a>
                <ul className="subMenu">
                  <li className="has-subMenu-left">
                    <a href="#" className="">
                      <img
                        src="img/svg/shopping-cart.svg"
                        alt="shopping-cart"
                        className="svg nav-icon"
                      />
                      <span className="menu-text">Firestore Crud</span>
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a className="" href="firestore.html">
                          View All
                        </a>
                      </li>
                      <li>
                        <a className="" href="firestore-add.html">
                          Add New
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="has-subMenu">
                <a href="#" className="">
                  Features
                </a>
                <ul className="subMenu">
                  <li>
                    <a href="editors.html" className="">
                      <img
                        src="img/svg/edit.svg"
                        alt="svg"
                        className="svg nav-icon"
                      />
                      <span className="menu-text">Editors</span>
                    </a>
                  </li>
                  <li className="has-subMenu-left">
                    <a href="#" className="">
                      <img
                        src="img/svg/grid.svg"
                        alt="grid"
                        className="svg nav-icon"
                      />
                      <span className="menu-text">Icons</span>
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="unicon-icons.html" className="">
                          Unicon Icons
                        </a>
                      </li>
                      <li>
                        <a href="fontawesome.html" className="">
                          Font Awesome
                        </a>
                      </li>
                      <li>
                        <a href="lineawesome.html" className="">
                          Line Awesome
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-subMenu-left">
                    <a href="#" className="">
                      <img
                        src="img/svg/bar-chart-2.svg"
                        alt="bar-chart-2"
                        className="svg nav-icon"
                      />
                      <span className="menu-text">Charts</span>
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a className="" href="charts.html">
                          Chart JS
                        </a>
                      </li>
                      <li>
                        <a className="" href="google-chart.html">
                          Google Charts
                        </a>
                      </li>
                      <li>
                        <a className="" href="peity-chart.html">
                          Peity Charts
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-subMenu-left">
                    <a href="#" className="">
                      <img
                        src="img/svg/disc.svg"
                        alt="disc"
                        className="svg nav-icon"
                      />
                      <span className="menu-text">Froms</span>
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a className="" href="form.html">
                          Basics
                        </a>
                      </li>
                      <li>
                        <a className="" href="form-layouts.html">
                          Layouts
                        </a>
                      </li>
                      <li>
                        <a className="" href="form-elements.html">
                          Elements
                        </a>
                      </li>
                      <li>
                        <a className="" href="form-components.html">
                          Components
                        </a>
                      </li>
                      <li>
                        <a className="" href="form-validations.html">
                          Validations
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-subMenu-left">
                    <a href="#" className="">
                      <img
                        src="img/svg/map.svg"
                        alt="map"
                        className="svg nav-icon"
                      />
                      <span className="menu-text">Maps</span>
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="google-map.html" className="">
                          Google Maps
                        </a>
                      </li>
                      <li>
                        <a href="leaflet-map.html" className="">
                          Leaflet Maps
                        </a>
                      </li>
                      <li>
                        <a href="vector-map.html" className="">
                          Vector Maps
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-subMenu-left">
                    <a href="#" className="">
                      <img
                        src="img/svg/server.svg"
                        alt="server"
                        className="svg nav-icon"
                      />
                      <span className="menu-text">Widget</span>
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a className="" href="widget-charts.html">
                          Chart
                        </a>
                      </li>
                      <li>
                        <a className="" href="widget-mixed.html">
                          Mixed
                        </a>
                      </li>
                      <li>
                        <a className="" href="widget-card.html">
                          Card
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-subMenu-left">
                    <a href="#" className="">
                      <img
                        src="img/svg/square.svg"
                        alt="square"
                        className="svg nav-icon"
                      />
                      <span className="menu-text">Wizards</span>
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="checkout-wizard6.html" className="">
                          Wizard 1
                        </a>
                      </li>
                      <li>
                        <a href="checkout-wizard7.html" className="">
                          Wizard 2
                        </a>
                      </li>
                      <li>
                        <a href="checkout-wizard8.html" className="">
                          Wizard 3
                        </a>
                      </li>
                      <li>
                        <a href="checkout-wizard9.html" className="">
                          Wizard 4
                        </a>
                      </li>
                      <li>
                        <a href="checkout-wizard10.html" className="">
                          Wizard 5
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="has-subMenu-left">
                    <a href="#" className="">
                      <img
                        src="img/svg/book.svg"
                        alt="book"
                        className="svg nav-icon"
                      />
                      <span className="menu-text">Knowledge Base</span>
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a className="" href="knowledgebase.html">
                          Knowledge Base
                        </a>
                      </li>
                      <li>
                        <a className="" href="knowledgebase-2.html">
                          All Article
                        </a>
                      </li>
                      <li>
                        <a className="" href="knowledgebase-3.html">
                          Single Article
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="mega-item has-subMenu">
                <a href="#" className="">
                  Pages
                </a>
                <ul className="megaMenu-wrapper megaMenu-small">
                  <li>
                    <ul>
                      <li>
                        <a href="changelog.html" className="">
                          <span className="menu-text">Changelog</span>
                          <span className="badge badge-info-10 menuItem rounded-pill">
                            1.1.2
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="profile-setting.html" className="">
                          <span className="menu-text">Settings</span>
                        </a>
                      </li>
                      <li>
                        <a href="gallery.html" className="">
                          <span className="menu-text">Gallery 1</span>
                        </a>
                      </li>
                      <li>
                        <a href="gallery2.html" className="">
                          <span className="menu-text">Gallery 2</span>
                        </a>
                      </li>
                      <li>
                        <a href="pricing.html" className="">
                          <span className="menu-text">Pricing</span>
                        </a>
                      </li>
                      <li>
                        <a href="banner.html" className="">
                          <span className="menu-text">Banners</span>
                        </a>
                      </li>
                      <li>
                        <a href="testimonial.html" className="">
                          <span className="menu-text">Testimonial</span>
                        </a>
                      </li>
                      <li>
                        <a href="faq.html" className="">
                          <span className="menu-text">FAQ's</span>
                        </a>
                      </li>
                      <li>
                        <a href="search.html" className="">
                          <span className="menu-text">Search Results</span>
                        </a>
                      </li>
                      <li>
                        <a href="blank.html" className="">
                          <span className="menu-text">Blank Page</span>
                        </a>
                      </li>
                      <li>
                        <a className="" href="knowledgebase.html">
                          Knowledge
                          <span className="menu-text">Base</span>
                        </a>
                      </li>
                      <li>
                        <a className="" href="knowledgebase-2.html">
                          All
                          <span className="menu-text">Article</span>
                        </a>
                      </li>
                      <li>
                        <a className="" href="knowledgebase-3.html">
                          <span className="menu-text">Single Article</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <a href="support.html" className="">
                          <span className="menu-text">Support Center</span>
                        </a>
                      </li>
                      <li>
                        <a href="blog.html" className="active">
                          Blog Style 1
                        </a>
                      </li>
                      <li>
                        <a href="blog2.html" className="">
                          Blog Style 2
                        </a>
                      </li>
                      <li>
                        <a href="blog3.html" className="">
                          Blog Style 3
                        </a>
                      </li>
                      <li>
                        <a href="blog-details.html" className="">
                          Blog Details
                        </a>
                      </li>
                      <li>
                        <a href="course.html" className="">
                          Course
                        </a>
                      </li>
                      <li>
                        <a href="course-details.html" className="">
                          Course Details
                        </a>
                      </li>
                      <li>
                        <a href="terms.html" className="">
                          <span className="menu-text">
                            Terms &amp; Conditions
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="maintenance.html" className="">
                          <span className="menu-text">Maintenance</span>
                        </a>
                      </li>
                      <li>
                        <a href="404.html" className="">
                          <span className="menu-text">404</span>
                        </a>
                      </li>
                      <li>
                        <a href="coming-soon.html" className="">
                          <span className="menu-text">Coming Soon</span>
                        </a>
                      </li>
                      <li>
                        <a href="login.html" className="">
                          <span className="menu-text">Log In</span>
                        </a>
                      </li>
                      <li>
                        <a href="sign-up.html" className="">
                          <span className="menu-text">Sign Up</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="mega-item has-subMenu">
                <a href="#" className="">
                  Components
                </a>
                <ul className="megaMenu-wrapper megaMenu-wide">
                  <li>
                    <span className="mega-title">Components</span>
                    <ul>
                      <li>
                        <a className="" href="alert.html">
                          Alert
                        </a>
                      </li>
                      <li>
                        <a className="" href="avatar.html">
                          Avatar
                        </a>
                      </li>
                      <li>
                        <a className="" href="badge.html">
                          Badge
                        </a>
                      </li>
                      <li>
                        <a className="" href="breadcrumbs.html">
                          Breadcrumb
                        </a>
                      </li>
                      <li>
                        <a className="" href="buttons.html">
                          Button
                        </a>
                      </li>
                      <li>
                        <a className="" href="cards.html">
                          Cards
                        </a>
                      </li>
                      <li>
                        <a className="" href="carousel.html">
                          Carousel
                        </a>
                      </li>
                      <li>
                        <a className="" href="checkbox.html">
                          Checkbox
                        </a>
                      </li>
                      <li>
                        <a className="" href="collapse.html">
                          Collapse
                        </a>
                      </li>
                      <li>
                        <a className="" href="comments.html">
                          Comments
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="mega-title">Components</span>
                    <ul>
                      <li>
                        <a className="" href="dashboard-base.html">
                          Typography
                        </a>
                      </li>
                      <li>
                        <a className="" href="date-picker.html">
                          DatePicker
                        </a>
                      </li>
                      <li>
                        <a className="" href="drawer.html">
                          Drawer
                        </a>
                      </li>
                      <li>
                        <a className="" href="drag-drop.html">
                          Drag &amp; Drop
                        </a>
                      </li>
                      <li>
                        <a className="" href="dropdown.html">
                          Dropdown
                        </a>
                      </li>
                      <li>
                        <a className="" href="empty.html">
                          Empty
                        </a>
                      </li>
                      <li>
                        <a className="" href="input.html">
                          Input
                        </a>
                      </li>
                      <li>
                        <a className="" href="list.html">
                          List
                        </a>
                      </li>
                      <li>
                        <a className="" href="menu.html">
                          Menu
                        </a>
                      </li>
                      <li>
                        <a className="" href="message.html">
                          Message
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="mega-title">Components</span>
                    <ul>
                      <li>
                        <a className="" href="modal.html">
                          Modals
                        </a>
                      </li>
                      <li>
                        <a className="" href="notifications.html">
                          Notification
                        </a>
                      </li>
                      <li>
                        <a className="" href="page-header.html">
                          Page Headers
                        </a>
                      </li>
                      <li>
                        <a className="" href="pagination.html">
                          Paginations
                        </a>
                      </li>
                      <li>
                        <a className="" href="progressbar.html">
                          Progress
                        </a>
                      </li>
                      <li>
                        <a className="" href="radio.html">
                          Radio
                        </a>
                      </li>
                      <li>
                        <a className="" href="rate.html">
                          Rate
                        </a>
                      </li>
                      <li>
                        <a className="" href="result.html">
                          Result
                        </a>
                      </li>
                      <li>
                        <a className="" href="select.html">
                          Select
                        </a>
                      </li>
                      <li>
                        <a className="" href="skeleton.html">
                          Skeleton
                        </a>
                      </li>
                      <li>
                        <a className="" href="time-picker.html">
                          Timepicker
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="mega-title">Components</span>
                    <ul>
                      <li>
                        <a className="" href="slider.html">
                          Slider
                        </a>
                      </li>
                      <li>
                        <a className="" href="spin.html">
                          Spinner
                        </a>
                      </li>
                      <li>
                        <a className="" href="statistics.html">
                          Statistic
                        </a>
                      </li>
                      <li>
                        <a className="" href="steps.html">
                          Steps
                        </a>
                      </li>
                      <li>
                        <a className="" href="switch.html">
                          Switch
                        </a>
                      </li>
                      <li>
                        <a className="" href="tab.html">
                          Tabs
                        </a>
                      </li>
                      <li>
                        <a className="" href="tag.html">
                          Tags
                        </a>
                      </li>
                      <li>
                        <a className="" href="timeline.html">
                          Timeline
                        </a>
                      </li>
                      <li>
                        <a className="" href="timeline-2.html">
                          Timeline 2
                        </a>
                      </li>
                      <li>
                        <a className="" href="timeline-3.html">
                          Timeline 3
                        </a>
                      </li>
                      <li>
                        <a className="" href="uploads.html">
                          Upload
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* ends: navbar-left */}
      <div className="navbar-right">
        <ul className="navbar-right__menu">
          <li className="nav-search">
            <a href="#" className="search-toggle">
              <i className="uil uil-search" />
              <i className="uil uil-times" />
            </a>
            <form action="/" className="search-form-topMenu">
              <span className="search-icon uil uil-search" />
              <input
                className="form-control me-sm-2 box-shadow-none"
                type="search"
                placeholder="Search..."
                aria-label="Search"
              />
            </form>
          </li>
          <li className="nav-message">
            <div className="dropdown-custom">
              <a href="javascript:;" className="nav-item-toggle icon-active">
                <img className="svg" src="img/svg/message.svg" alt="img" />
              </a>
              <div className="dropdown-parent-wrapper">
                <div className="dropdown-wrapper">
                  <h2 className="dropdown-wrapper__title">
                    Messages{" "}
                    <span className="badge-circle badge-success ms-1">2</span>
                  </h2>
                  <ul>
                    <li className="author-online has-new-message">
                      <div className="user-avater">
                        <img src="img/team-1.png" alt="" />
                      </div>
                      <div className="user-message">
                        <p>
                          <a
                            href=""
                            className="subject stretched-link text-truncate"
                            style={{ maxWidth: 180 }}
                          >
                            Web Design
                          </a>
                          <span className="time-posted">3 hrs ago</span>
                        </p>
                        <p>
                          <span
                            className="desc text-truncate"
                            style={{ maxWidth: 215 }}
                          >
                            Lorem ipsum dolor amet cosec Lorem ipsum
                          </span>
                          <span className="msg-count badge-circle badge-success badge-sm">
                            1
                          </span>
                        </p>
                      </div>
                    </li>
                    <li className="author-offline has-new-message">
                      <div className="user-avater">
                        <img src="img/team-1.png" alt="" />
                      </div>
                      <div className="user-message">
                        <p>
                          <a
                            href=""
                            className="subject stretched-link text-truncate"
                            style={{ maxWidth: 180 }}
                          >
                            Web Design
                          </a>
                          <span className="time-posted">3 hrs ago</span>
                        </p>
                        <p>
                          <span
                            className="desc text-truncate"
                            style={{ maxWidth: 215 }}
                          >
                            Lorem ipsum dolor amet cosec Lorem ipsum
                          </span>
                          <span className="msg-count badge-circle badge-success badge-sm">
                            1
                          </span>
                        </p>
                      </div>
                    </li>
                    <li className="author-online has-new-message">
                      <div className="user-avater">
                        <img src="img/team-1.png" alt="" />
                      </div>
                      <div className="user-message">
                        <p>
                          <a
                            href=""
                            className="subject stretched-link text-truncate"
                            style={{ maxWidth: 180 }}
                          >
                            Web Design
                          </a>
                          <span className="time-posted">3 hrs ago</span>
                        </p>
                        <p>
                          <span
                            className="desc text-truncate"
                            style={{ maxWidth: 215 }}
                          >
                            Lorem ipsum dolor amet cosec Lorem ipsum
                          </span>
                          <span className="msg-count badge-circle badge-success badge-sm">
                            1
                          </span>
                        </p>
                      </div>
                    </li>
                    <li className="author-offline">
                      <div className="user-avater">
                        <img src="img/team-1.png" alt="" />
                      </div>
                      <div className="user-message">
                        <p>
                          <a
                            href=""
                            className="subject stretched-link text-truncate"
                            style={{ maxWidth: 180 }}
                          >
                            Web Design
                          </a>
                          <span className="time-posted">3 hrs ago</span>
                        </p>
                        <p>
                          <span
                            className="desc text-truncate"
                            style={{ maxWidth: 215 }}
                          >
                            Lorem ipsum dolor amet cosec Lorem ipsum
                          </span>
                        </p>
                      </div>
                    </li>
                    <li className="author-offline">
                      <div className="user-avater">
                        <img src="img/team-1.png" alt="" />
                      </div>
                      <div className="user-message">
                        <p>
                          <a
                            href=""
                            className="subject stretched-link text-truncate"
                            style={{ maxWidth: 180 }}
                          >
                            Web Design
                          </a>
                          <span className="time-posted">3 hrs ago</span>
                        </p>
                        <p>
                          <span
                            className="desc text-truncate"
                            style={{ maxWidth: 215 }}
                          >
                            Lorem ipsum dolor amet cosec Lorem ipsum
                          </span>
                        </p>
                      </div>
                    </li>
                  </ul>
                  <a href="" className="dropdown-wrapper__more">
                    See All Message
                  </a>
                </div>
              </div>
            </div>
          </li>
          {/* ends: nav-message */}
          <li className="nav-notification">
            <div className="dropdown-custom">
              <a href="javascript:;" className="nav-item-toggle icon-active">
                <img className="svg" src="img/svg/alarm.svg" alt="img" />
              </a>
              <div className="dropdown-parent-wrapper">
                <div className="dropdown-wrapper">
                  <h2 className="dropdown-wrapper__title">
                    Notifications{" "}
                    <span className="badge-circle badge-warning ms-1">4</span>
                  </h2>
                  <ul>
                    <li className="nav-notification__single nav-notification__single--unread d-flex flex-wrap">
                      <div className="nav-notification__type nav-notification__type--primary">
                        <img
                          className="svg"
                          src="img/svg/inbox.svg"
                          alt="inbox"
                        />
                      </div>
                      <div className="nav-notification__details">
                        <p>
                          <a
                            href=""
                            className="subject stretched-link text-truncate"
                            style={{ maxWidth: 180 }}
                          >
                            James
                          </a>
                          <span>sent you a message</span>
                        </p>
                        <p>
                          <span className="time-posted">5 hours ago</span>
                        </p>
                      </div>
                    </li>
                    <li className="nav-notification__single nav-notification__single--unread d-flex flex-wrap">
                      <div className="nav-notification__type nav-notification__type--secondary">
                        <img
                          className="svg"
                          src="img/svg/upload.svg"
                          alt="upload"
                        />
                      </div>
                      <div className="nav-notification__details">
                        <p>
                          <a
                            href=""
                            className="subject stretched-link text-truncate"
                            style={{ maxWidth: 180 }}
                          >
                            James
                          </a>
                          <span>sent you a message</span>
                        </p>
                        <p>
                          <span className="time-posted">5 hours ago</span>
                        </p>
                      </div>
                    </li>
                    <li className="nav-notification__single nav-notification__single--unread d-flex flex-wrap">
                      <div className="nav-notification__type nav-notification__type--success">
                        <img
                          className="svg"
                          src="img/svg/log-in.svg"
                          alt="log-in"
                        />
                      </div>
                      <div className="nav-notification__details">
                        <p>
                          <a
                            href=""
                            className="subject stretched-link text-truncate"
                            style={{ maxWidth: 180 }}
                          >
                            James
                          </a>
                          <span>sent you a message</span>
                        </p>
                        <p>
                          <span className="time-posted">5 hours ago</span>
                        </p>
                      </div>
                    </li>
                    <li className="nav-notification__single nav-notification__single d-flex flex-wrap">
                      <div className="nav-notification__type nav-notification__type--info">
                        <img
                          className="svg"
                          src="img/svg/at-sign.svg"
                          alt="at-sign"
                        />
                      </div>
                      <div className="nav-notification__details">
                        <p>
                          <a
                            href=""
                            className="subject stretched-link text-truncate"
                            style={{ maxWidth: 180 }}
                          >
                            James
                          </a>
                          <span>sent you a message</span>
                        </p>
                        <p>
                          <span className="time-posted">5 hours ago</span>
                        </p>
                      </div>
                    </li>
                    <li className="nav-notification__single nav-notification__single d-flex flex-wrap">
                      <div className="nav-notification__type nav-notification__type--danger">
                        <img
                          src="img/svg/heart.svg"
                          alt="heart"
                          className="svg"
                        />
                      </div>
                      <div className="nav-notification__details">
                        <p>
                          <a
                            href=""
                            className="subject stretched-link text-truncate"
                            style={{ maxWidth: 180 }}
                          >
                            James
                          </a>
                          <span>sent you a message</span>
                        </p>
                        <p>
                          <span className="time-posted">5 hours ago</span>
                        </p>
                      </div>
                    </li>
                  </ul>
                  <a href="" className="dropdown-wrapper__more">
                    See all incoming activity
                  </a>
                </div>
              </div>
            </div>
          </li>
          {/* ends: .nav-notification */}
          <li className="nav-settings">
            <div className="dropdown-custom">
              <a href="javascript:;" className="nav-item-toggle">
                <img src="img/setting.png" alt="setting" />
              </a>
              <div className="dropdown-parent-wrapper">
                <div className="dropdown-wrapper dropdown-wrapper--large">
                  <ul className="list-settings">
                    <li className="d-flex">
                      <div className="me-3">
                        <img src="img/mail.png" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <h6>
                          <a href="" className="stretched-link">
                            All Features
                          </a>
                        </h6>
                        <p>Introducing Increment subscriptions </p>
                      </div>
                    </li>
                    <li className="d-flex">
                      <div className="me-3">
                        <img src="img/color-palette.png" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <h6>
                          <a href="" className="stretched-link">
                            Themes
                          </a>
                        </h6>
                        <p>Third party themes that are compatible</p>
                      </div>
                    </li>
                    <li className="d-flex">
                      <div className="me-3">
                        <img src="img/home.png" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <h6>
                          <a href="" className="stretched-link">
                            Payments
                          </a>
                        </h6>
                        <p>We handle billions of dollars</p>
                      </div>
                    </li>
                    <li className="d-flex">
                      <div className="me-3">
                        <img src="img/video-camera.png" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <h6>
                          <a href="" className="stretched-link">
                            Design Mockups
                          </a>
                        </h6>
                        <p>Share planning visuals with clients</p>
                      </div>
                    </li>
                    <li className="d-flex">
                      <div className="me-3">
                        <img src="img/document.png" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <h6>
                          <a href="" className="stretched-link">
                            Content Planner
                          </a>
                        </h6>
                        <p>Centralize content gethering and editing</p>
                      </div>
                    </li>
                    <li className="d-flex">
                      <div className="me-3">
                        <img src="img/microphone.png" alt="" />
                      </div>
                      <div className="flex-grow-1">
                        <h6>
                          <a href="" className="stretched-link">
                            Diagram Maker
                          </a>
                        </h6>
                        <p>Plan user flows &amp; test scenarios</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          {/* ends: .nav-support */}
          <li className="nav-flag-select">
            <div className="dropdown-custom">
              <a href="javascript:;" className="nav-item-toggle">
                <img src="img/flag.png" alt="" className="rounded-circle" />
              </a>
              <div className="dropdown-parent-wrapper">
                <div className="dropdown-wrapper dropdown-wrapper--small">
                  <a href="">
                    <img src="img/eng.png" alt="" /> English
                  </a>
                  <a href="">
                    <img src="img/ger.png" alt="" /> German
                  </a>
                  <a href="">
                    <img src="img/spa.png" alt="" /> Spanish
                  </a>
                  <a href="">
                    <img src="img/tur.png" alt="" /> Turkish
                  </a>
                  <a href="">
                    <img src="img/iraq.png" alt="" /> Arabic
                  </a>
                </div>
              </div>
            </div>
          </li>
          {/* ends: .nav-flag-select */}
          <li className="nav-author">
            <div className="dropdown-custom">
              <a href="javascript:;" className="nav-item-toggle">
                <img
                  src="img/author-nav.jpg"
                  alt=""
                  className="rounded-circle"
                />
                <span className="nav-item__title">
                  Danial
                  <i className="las la-angle-down nav-item__arrow" />
                </span>
              </a>
              <div className="dropdown-parent-wrapper">
                <div className="dropdown-wrapper">
                  <div className="nav-author__info">
                    <div className="author-img">
                      <img
                        src="img/author-nav.jpg"
                        alt=""
                        className="rounded-circle"
                      />
                    </div>
                    <div>
                      <h6>Rabbi Islam Rony</h6>
                      <span>UI Designer</span>
                    </div>
                  </div>
                  <div className="nav-author__options">
                    <ul>
                      <li>
                        <a href="">
                          <i className="uil uil-user" /> Profile
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="uil uil-setting" />
                          Settings
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="uil uil-key-skeleton" /> Billing
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="uil uil-users-alt" /> Activity
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="uil uil-bell" /> Help
                        </a>
                      </li>
                    </ul>
                    <a href="" className="nav-author__signout">
                      <i className="uil uil-sign-out-alt" /> Sign Out
                    </a>
                  </div>
                </div>
                {/* ends: .dropdown-wrapper */}
              </div>
            </div>
          </li>
          {/* ends: .nav-author */}
        </ul>
        {/* ends: .navbar-right__menu */}
        <div className="navbar-right__mobileAction d-md-none">
          <a href="#" className="btn-search">
            <img
              src="img/svg/search.svg"
              alt="search"
              className="svg feather-search"
            />
            <img src="img/svg/x.svg" alt="x" className="svg feather-x" />
          </a>
          <a href="#" className="btn-author-action">
            <img
              className="svg"
              src="img/svg/more-vertical.svg"
              alt="more-vertical"
            />
          </a>
        </div>
      </div>
      {/* ends: .navbar-right */}
    </nav>
  </header>
  <main className="main-content">
  <div className="sidebar-wrapper">
    <div className="sidebar sidebar-collapse" id="sidebar">
      <div className="sidebar__menu-group">
        <ul className="sidebar_nav">
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-create-dashboard" />
              <span className="menu-text">Dashboard</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="index.html">Demo 1</a>
              </li>
              <li className="">
                <a href="demo2.html">Demo 2</a>
              </li>
              <li className="">
                <a href="demo3.html">Demo 3</a>
              </li>
              <li className="">
                <a href="demo4.html">Demo 4</a>
              </li>
              <li className="">
                <a href="demo5.html">Demo 5</a>
              </li>
              <li className="">
                <a href="demo6.html">Demo 6</a>
              </li>
              <li className="">
                <a href="demo7.html">Demo 7</a>
              </li>
              <li className="">
                <a href="demo8.html">Demo 8</a>
              </li>
              <li className="">
                <a href="demo9.html">Demo 9</a>
              </li>
              <li className="">
                <a href="demo10.html">Demo 10</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-window-section" />
              <span className="menu-text">Layouts</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="l_sidebar">
                <a href="#" data-layout="light">
                  Light Mode
                </a>
              </li>
              <li className="l_sidebar">
                <a href="#" data-layout="dark">
                  Dark Mode
                </a>
              </li>
              <li className="l_navbar">
                <a href="#" data-layout="top">
                  Top Menu
                </a>
              </li>
              <li className="layout">
                <a href="../rtl">RTL</a>
              </li>
              <li className="layout">
                <a href="../ltr">LTR</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="changelog.html" className="">
              <span className="nav-icon uil uil-arrow-growth" />
              <span className="menu-text">Changelog</span>
              <span className="badge badge-info-10 menuItem rounded-pill">
                1.1.4
              </span>
            </a>
          </li>
          <li className="menu-title mt-30">
            <span>Applications</span>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-envelope" />
              <span className="menu-text">Email</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="inbox.html">Inbox</a>
              </li>
              <li className="">
                <a href="read-email.html">Read Email</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="chat.html" className="">
              <span className="nav-icon uil uil-chat" />
              <span className="menu-text">Chat</span>
              <span className="badge badge-success menuItem rounded-circle">
                3
              </span>
            </a>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-bag" />
              <span className="menu-text text-initial">eCommerce</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="products.html">Products</a>
              </li>
              <li className="">
                <a href="product-details.html">Product Details</a>
              </li>
              <li className="">
                <a href="add-product.html">Product Add</a>
              </li>
              <li className="">
                <a href="add-product.html">Product Edit</a>
              </li>
              <li className="">
                <a href="cart.html">Cart</a>
              </li>
              <li className="">
                <a href="order.html">Orders</a>
              </li>
              <li className="">
                <a href="sellers.html">Sellers</a>
              </li>
              <li className="">
                <a href="invoice.html">Invoices</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-folder" />
              <span className="menu-text">Project</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="projects.html">Project</a>
              </li>
              <li className="">
                <a href="application-ui.html">Project Details</a>
              </li>
              <li className="">
                <a href="create.html">Create Project</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="calendar.html" className="">
              <span className="nav-icon uil uil-calendar-alt" />
              <span className="menu-text">Calendar</span>
            </a>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-users-alt" />
              <span className="menu-text">Users</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="users-membar.html">Team</a>
              </li>
              <li className="">
                <a href="users-card.html">Users Grid</a>
              </li>
              <li className="">
                <a href="users-list.html">Users List</a>
              </li>
              <li className="">
                <a href="users-card2.html">Users Grid style</a>
              </li>
              <li className="">
                <a href="users-group.html">Users Group</a>
              </li>
              <li className="">
                <a href="user-info.html">Add User</a>
              </li>
              <li className="">
                <a href="users-datatable.html">Users Table</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-at" />
              <span className="menu-text">Contact</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="contact-2.html">Contact Grid</a>
              </li>
              <li className="">
                <a href="contact-1.html">Contact List</a>
              </li>
              <li className="">
                <a href="contact-3.html">Contact Create</a>
              </li>
            </ul>
          </li>
          <li className="">
            <a href="note.html">
              <span className="nav-icon uil uil-clipboard-notes" />
              <span className="menu-text">Note</span>
            </a>
          </li>
          <li className="">
            <a href="to-do.html">
              <span className="nav-icon uil uil-check-square" />
              <span className="menu-text">To-Do</span>
            </a>
          </li>
          <li className="">
            <a href="kanban.html">
              <span className="nav-icon uil uil-expand-arrows" />
              <span className="menu-text">Kanban Board</span>
            </a>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-exchange" />
              <span className="menu-text">Import &amp; Export</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="import.html">Import</a>
              </li>
              <li className="">
                <a href="export.html">Export</a>
              </li>
              <li className="">
                <a href="export-selected.html">Export Selected</a>
              </li>
            </ul>
          </li>
          <li className="">
            <a href="file-manager.html">
              <span className="nav-icon uil uil-file" />
              <span className="menu-text">File Manager</span>
            </a>
          </li>
          <li className="">
            <a href="task-app.html">
              <span className="nav-icon uil uil-lightbulb-alt" />
              <span className="menu-text">Task App</span>
            </a>
          </li>
          <li className="">
            <a href="bookmarks.html">
              <span className="nav-icon uil uil-bookmark" />
              <span className="menu-text">Bookmarks</span>
            </a>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-apps" />
              <span className="menu-text">Social App</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="profile.html">My Profile</a>
              </li>
              <li className="">
                <a href="profile-setting.html">Profile Settings</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-books" />
              <span className="menu-text">Learning App</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="course.html">Course</a>
              </li>
              <li className="">
                <a href="course-details.html">Course Details</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-user" />
              <span className="menu-text">Support</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="support-ticket.html">Support Ticket</a>
              </li>
              <li className="">
                <a href="support-details.html">Ticket Details</a>
              </li>
              <li className="">
                <a href="new-ticket.html">New Ticket</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-search" />
              <span className="menu-text">JobSearch</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="jobSearch.html">Grid view</a>
              </li>
              <li className="">
                <a href="jobSearchList.html">List view</a>
              </li>
              <li className="">
                <a href="jobDetails.html">Details</a>
              </li>
              <li className="">
                <a href="job-apply.html">Apply</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-table" />
              <span className="menu-text">Table</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="basic-table.html">Basic Table</a>
              </li>
              <li className="">
                <a href="datatable.html">Data Table</a>
              </li>
              <li className="">
                <a href="dynamic-table.html">Dynamic Table</a>
              </li>
            </ul>
          </li>
          <li className="menu-title mt-30">
            <span>CRUD</span>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-database" />
              <span className="menu-text">Firestore Crud</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="firestore.html">View All</a>
              </li>
              <li className="">
                <a href="firestore-add.html">Add New</a>
              </li>
            </ul>
          </li>
          <li className="menu-title mt-30">
            <span>Features</span>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-layers" />
              <span className="menu-text">UI Elements</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="alert.html">Alert</a>
              </li>
              <li className="">
                <a href="avatar.html">Avatar</a>
              </li>
              <li className="">
                <a href="badge.html">Badge</a>
              </li>
              <li className="">
                <a href="breadcrumbs.html">Breadcrumb</a>
              </li>
              <li className="">
                <a href="buttons.html">Button</a>
              </li>
              <li className="">
                <a href="cards.html">Cards</a>
              </li>
              <li className="">
                <a href="carousel.html">Carousel</a>
              </li>
              <li className="">
                <a href="checkbox.html">Checkbox</a>
              </li>
              <li className="">
                <a href="collapse.html">Collapse</a>
              </li>
              <li className="">
                <a href="comments.html">Comments</a>
              </li>
              <li className="">
                <a href="date-picker.html">DatePicker</a>
              </li>
              <li className="">
                <a href="drawer.html">Drawer</a>
              </li>
              <li className="">
                <a href="drag-drop.html">Drag &amp; Drop</a>
              </li>
              <li className="">
                <a href="dropdown.html">Dropdown</a>
              </li>
              <li className="">
                <a href="empty.html">Empty</a>
              </li>
              <li className="">
                <a href="grid.html">Grid</a>
              </li>
              <li className="">
                <a href="input.html">Input</a>
              </li>
              <li className="">
                <a href="list.html">List</a>
              </li>
              <li className="">
                <a href="menu.html">Menu</a>
              </li>
              <li className="">
                <a href="message.html">Message</a>
              </li>
              <li className="">
                <a href="modal.html">Modals</a>
              </li>
              <li className="">
                <a href="notifications.html">Notification</a>
              </li>
              <li className="">
                <a href="page-header.html">Page Headers</a>
              </li>
              <li className="">
                <a href="pagination.html">Paginations</a>
              </li>
              <li className="">
                <a href="progressbar.html">Progress</a>
              </li>
              <li className="">
                <a href="radio.html">Radio</a>
              </li>
              <li className="">
                <a href="rate.html">Rate</a>
              </li>
              <li className="">
                <a href="result.html">Result</a>
              </li>
              <li className="">
                <a href="select.html">Select</a>
              </li>
              <li className="">
                <a href="skeleton.html">Skeleton</a>
              </li>
              <li className="">
                <a href="slider.html">Slider</a>
              </li>
              <li className="">
                <a href="spin.html">Spinner</a>
              </li>
              <li className="">
                <a href="statistics.html">Statistic</a>
              </li>
              <li className="">
                <a href="steps.html">Steps</a>
              </li>
              <li className="">
                <a href="switch.html">Switch</a>
              </li>
              <li className="">
                <a href="tab.html">Tabs</a>
              </li>
              <li className="">
                <a href="tag.html">Tags</a>
              </li>
              <li className="">
                <a href="timeline.html">Timeline</a>
              </li>
              <li className="">
                <a href="timeline-2.html">Timeline 2</a>
              </li>
              <li className="">
                <a href="timeline-3.html">Timeline 3</a>
              </li>
              <li className="">
                <a href="time-picker.html">Timepicker</a>
              </li>
              <li className="">
                <a href="dashboard-base.html">Typography</a>
              </li>
              <li className="">
                <a href="uploads.html">Upload</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-chart" />
              <span className="menu-text">Charts</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="charts.html">Chart JS</a>
              </li>
              <li className="">
                <a href="apexchart.html">
                  <span className="menu-text">Apexchart</span>
                  <span className="badge badge-success menuItem">New</span>
                </a>
              </li>
              <li className="">
                <a href="google-chart.html">Google Charts</a>
              </li>
              <li className="">
                <a href="peity-chart.html">Peity Charts</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-keyhole-circle" />
              <span className="menu-text">Froms</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="form.html">Basics</a>
              </li>
              <li className="">
                <a href="form-layouts.html">Layouts</a>
              </li>
              <li className="">
                <a href="form-elements.html">Elements</a>
              </li>
              <li className="">
                <a href="form-components.html">Components</a>
              </li>
              <li className="">
                <a href="form-validations.html">Validations</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-server" />
              <span className="menu-text">Widget</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="widget-charts.html">Chart</a>
              </li>
              <li className="">
                <a href="widget-mixed.html">Mixed</a>
              </li>
              <li className="">
                <a href="widget-card.html">Card</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-square" />
              <span className="menu-text">Wizards</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="checkout-wizard6.html">Wizard 1</a>
              </li>
              <li className="">
                <a href="checkout-wizard7.html">Wizard 2</a>
              </li>
              <li className="">
                <a href="checkout-wizard8.html">Wizard 3</a>
              </li>
              <li className="">
                <a href="checkout-wizard9.html">Wizard 4</a>
              </li>
              <li className="">
                <a href="checkout-wizard10.html">Wizard 5</a>
              </li>
            </ul>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-grid" />
              <span className="menu-text">Icons</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="unicon-icons.html">Unicon Icons</a>
              </li>
              <li className="">
                <a href="fontawesome.html">Font Awesome</a>
              </li>
              <li className="">
                <a href="lineawesome.html">Line Awesome</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="editors.html" className="">
              <span className="nav-icon uil uil-edit" />
              <span className="menu-text">Editors</span>
            </a>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-map" />
              <span className="menu-text">Maps</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="google-map.html">Google Maps</a>
              </li>
              <li className="">
                <a href="leaflet-map.html">Leaflet Maps</a>
              </li>
              <li className="">
                <a href="vector-map.html">Vector Maps</a>
              </li>
            </ul>
          </li>
          <li className="menu-title mt-30">
            <span>Pages</span>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-images" />
              <span className="menu-text">Gallery</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="gallery.html">Gallery 1</a>
              </li>
              <li className="">
                <a href="gallery2.html">Gallery 2</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="pricing.html" className="">
              <span className="nav-icon uil uil-bill" />
              <span className="menu-text">Pricing</span>
            </a>
          </li>
          <li className="">
            <a href="banner.html">
              <span className="nav-icon uil uil-thumbs-up" />
              <span className="menu-text">Banners</span>
            </a>
          </li>
          <li className="">
            <a href="testimonial.html">
              <span className="nav-icon uil uil-book-open" />
              <span className="menu-text">Testimonial</span>
            </a>
          </li>
          <li className="">
            <a href="faq.html">
              <span className="nav-icon uil uil-question-circle" />
              <span className="menu-text">FAQ's</span>
            </a>
          </li>
          <li className="">
            <a href="search.html">
              <span className="nav-icon uil uil-credit-card-search" />
              <span className="menu-text">Search Results</span>
            </a>
          </li>
          <li className="">
            <a href="blank.html">
              <span className="nav-icon uil uil-circle" />
              <span className="menu-text">Blank Page</span>
            </a>
          </li>
          <li className="has-child">
            <a href="#" className="">
              <span className="nav-icon uil uil-window" />
              <span className="menu-text">Knowledge Base</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="">
                <a href="knowledgebase.html">Knowledge Base</a>
              </li>
              <li className="">
                <a href="knowledgebase-2.html">All Article</a>
              </li>
              <li className="">
                <a href="knowledgebase-3.html">Single Article</a>
              </li>
            </ul>
          </li>
          <li className="">
            <a href="support.html">
              <span className="nav-icon uil uil-headphones" />
              <span className="menu-text">Support Center</span>
            </a>
          </li>
          <li className="has-child open">
            <a href="#" className=" active">
              <span className="nav-icon uil uil-images" />
              <span className="menu-text">Blog</span>
              <span className="toggle-icon" />
            </a>
            <ul>
              <li className="active">
                <a href="blog.html">style 1</a>
              </li>
              <li className="">
                <a href="blog2.html">Style 2</a>
              </li>
              <li className="">
                <a href="blog3.html">Style 3</a>
              </li>
              <li className="">
                <a href="blog-details.html">Details</a>
              </li>
            </ul>
          </li>
          <li className="">
            <a href="terms.html">
              <span className="nav-icon uil uil-question-circle" />
              <span className="menu-text">Terms &amp; Conditions</span>
            </a>
          </li>
          <li className="">
            <a href="maintenance.html">
              <span className="nav-icon uil uil-airplay" />
              <span className="menu-text">Maintenance</span>
            </a>
          </li>
          <li className="">
            <a href="404.html">
              <span className="nav-icon uil uil-exclamation-triangle" />
              <span className="menu-text">404</span>
            </a>
          </li>
          <li className="">
            <a href="coming-soon.html">
              <span className="nav-icon uil uil-sync" />
              <span className="menu-text">Coming Soon</span>
            </a>
          </li>
          <li className="">
            <a href="login.html">
              <span className="nav-icon uil uil-signin" />
              <span className="menu-text">Log In</span>
            </a>
          </li>
          <li className="">
            <a href="sign-up.html">
              <span className="nav-icon uil uil-sign-out-alt" />
              <span className="menu-text">Sign Up</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div className="contents">
    <div className="blog-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-main">
              <h4 className="text-capitalize breadcrumb-title">Blog</h4>
              <div className="breadcrumb-action justify-content-center flex-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="uil uil-estate" />
                        Dashboard
                      </a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">Pages</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Blog style
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 mb-25">
            <div className="blog-card">
              <div className="blog-card__thumbnail">
                <a href="#">
                  <img src="img/bg2.png" alt="" />
                </a>
              </div>
              <div className="blog-card__details">
                <div className="blog-card__content">
                  <div className="blog-card__title--top">15 March 2021</div>
                  <h4 className="blog-card__title">
                    <a href="#" className="entry-title" rel="bookmark">
                      The Ultimate Glossary of Terms About
                    </a>
                  </h4>
                  <p>
                    There are many variations of passages of majority have
                    suffered alteration in some form
                  </p>
                </div>
                <div className="blog-card__meta">
                  <div className="blog-card__meta-profile">
                    <img src="img/alex-suprun.png" alt="" />
                    <span>Machel Bold</span>
                  </div>
                  <div className="blog-card__meta-count">
                    <ul>
                      <li>
                        <div className="blog-card__meta-reaction">
                          <img
                            src="img/svg/heart.svg"
                            alt="heart"
                            className="svg"
                          />
                          <span className="blog-card__meta-reaction-like">
                            15 K
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="blog-card__meta-doc-wrapper">
                          <img
                            src="img/svg/file-text.svg"
                            alt="file-text"
                            className="svg"
                          />
                          <span className="blog-card__meta-doc">75</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 mb-25">
            <div className="blog-card">
              <div className="blog-card__thumbnail">
                <a href="#">
                  <img src="img/bg3.png" alt="" />
                </a>
              </div>
              <div className="blog-card__details">
                <div className="blog-card__content">
                  <div className="blog-card__title--top">15 March 2021</div>
                  <h4 className="blog-card__title">
                    <a href="#" className="entry-title" rel="bookmark">
                      Multiple Dashboard Design Idea
                    </a>
                  </h4>
                  <p>
                    There are many variations of passages of majority have
                    suffered alteration in some form
                  </p>
                </div>
                <div className="blog-card__meta">
                  <div className="blog-card__meta-profile">
                    <img src="img/alex-suprun.png" alt="" />
                    <span>Machel Bold</span>
                  </div>
                  <div className="blog-card__meta-count">
                    <ul>
                      <li>
                        <div className="blog-card__meta-reaction">
                          <img
                            src="img/svg/heart.svg"
                            alt="heart"
                            className="svg"
                          />
                          <span className="blog-card__meta-reaction-like">
                            15 K
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="blog-card__meta-doc-wrapper">
                          <img
                            src="img/svg/file-text.svg"
                            alt="file-text"
                            className="svg"
                          />
                          <span className="blog-card__meta-doc">75</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 mb-25">
            <div className="blog-card">
              <div className="blog-card__thumbnail">
                <a href="#">
                  <img src="img/bg4.png" alt="" />
                </a>
              </div>
              <div className="blog-card__details">
                <div className="blog-card__content">
                  <div className="blog-card__title--top">15 March 2021</div>
                  <h4 className="blog-card__title">
                    <a href="#" className="entry-title" rel="bookmark">
                      How to Create a Niche Based Dashboard
                    </a>
                  </h4>
                  <p>
                    There are many variations of passages of majority have
                    suffered alteration in some form
                  </p>
                </div>
                <div className="blog-card__meta">
                  <div className="blog-card__meta-profile">
                    <img src="img/alex-suprun.png" alt="" />
                    <span>Machel Bold</span>
                  </div>
                  <div className="blog-card__meta-count">
                    <ul>
                      <li>
                        <div className="blog-card__meta-reaction">
                          <img
                            src="img/svg/heart.svg"
                            alt="heart"
                            className="svg"
                          />
                          <span className="blog-card__meta-reaction-like">
                            15 K
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="blog-card__meta-doc-wrapper">
                          <img
                            src="img/svg/file-text.svg"
                            alt="file-text"
                            className="svg"
                          />
                          <span className="blog-card__meta-doc">75</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 mb-25">
            <div className="blog-card">
              <div className="blog-card__thumbnail">
                <a href="#">
                  <img src="img/bg2.png" alt="" />
                </a>
              </div>
              <div className="blog-card__details">
                <div className="blog-card__content">
                  <div className="blog-card__title--top">15 March 2021</div>
                  <h4 className="blog-card__title">
                    <a href="#" className="entry-title" rel="bookmark">
                      The Ultimate Glossary of Terms About
                    </a>
                  </h4>
                  <p>
                    There are many variations of passages of majority have
                    suffered alteration in some form
                  </p>
                </div>
                <div className="blog-card__meta">
                  <div className="blog-card__meta-profile">
                    <img src="img/alex-suprun.png" alt="" />
                    <span>Machel Bold</span>
                  </div>
                  <div className="blog-card__meta-count">
                    <ul>
                      <li>
                        <div className="blog-card__meta-reaction">
                          <img
                            src="img/svg/heart.svg"
                            alt="heart"
                            className="svg"
                          />
                          <span className="blog-card__meta-reaction-like">
                            15 K
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="blog-card__meta-doc-wrapper">
                          <img
                            src="img/svg/file-text.svg"
                            alt="file-text"
                            className="svg"
                          />
                          <span className="blog-card__meta-doc">75</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 mb-25">
            <div className="blog-card">
              <div className="blog-card__thumbnail">
                <a href="#">
                  <img src="img/bg3.png" alt="" />
                </a>
              </div>
              <div className="blog-card__details">
                <div className="blog-card__content">
                  <div className="blog-card__title--top">15 March 2021</div>
                  <h4 className="blog-card__title">
                    <a href="#" className="entry-title" rel="bookmark">
                      Multiple Dashboard Design Idea
                    </a>
                  </h4>
                  <p>
                    There are many variations of passages of majority have
                    suffered alteration in some form
                  </p>
                </div>
                <div className="blog-card__meta">
                  <div className="blog-card__meta-profile">
                    <img src="img/alex-suprun.png" alt="" />
                    <span>Machel Bold</span>
                  </div>
                  <div className="blog-card__meta-count">
                    <ul>
                      <li>
                        <div className="blog-card__meta-reaction">
                          <img
                            src="img/svg/heart.svg"
                            alt="heart"
                            className="svg"
                          />
                          <span className="blog-card__meta-reaction-like">
                            15 K
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="blog-card__meta-doc-wrapper">
                          <img
                            src="img/svg/file-text.svg"
                            alt="file-text"
                            className="svg"
                          />
                          <span className="blog-card__meta-doc">75</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 mb-25">
            <div className="blog-card">
              <div className="blog-card__thumbnail">
                <a href="#">
                  <img src="img/bg4.png" alt="" />
                </a>
              </div>
              <div className="blog-card__details">
                <div className="blog-card__content">
                  <div className="blog-card__title--top">15 March 2021</div>
                  <h4 className="blog-card__title">
                    <a href="#" className="entry-title" rel="bookmark">
                      How to Create a Niche Based Dashboard
                    </a>
                  </h4>
                  <p>
                    There are many variations of passages of majority have
                    suffered alteration in some form
                  </p>
                </div>
                <div className="blog-card__meta">
                  <div className="blog-card__meta-profile">
                    <img src="img/alex-suprun.png" alt="" />
                    <span>Machel Bold</span>
                  </div>
                  <div className="blog-card__meta-count">
                    <ul>
                      <li>
                        <div className="blog-card__meta-reaction">
                          <img
                            src="img/svg/heart.svg"
                            alt="heart"
                            className="svg"
                          />
                          <span className="blog-card__meta-reaction-like">
                            15 K
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="blog-card__meta-doc-wrapper">
                          <img
                            src="img/svg/file-text.svg"
                            alt="file-text"
                            className="svg"
                          />
                          <span className="blog-card__meta-doc">75</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 mb-25">
            <div className="blog-card">
              <div className="blog-card__thumbnail">
                <a href="#">
                  <img src="img/bg2.png" alt="" />
                </a>
              </div>
              <div className="blog-card__details">
                <div className="blog-card__content">
                  <div className="blog-card__title--top">15 March 2021</div>
                  <h4 className="blog-card__title">
                    <a href="#" className="entry-title" rel="bookmark">
                      The Ultimate Glossary of Terms About
                    </a>
                  </h4>
                  <p>
                    There are many variations of passages of majority have
                    suffered alteration in some form
                  </p>
                </div>
                <div className="blog-card__meta">
                  <div className="blog-card__meta-profile">
                    <img src="img/alex-suprun.png" alt="" />
                    <span>Machel Bold</span>
                  </div>
                  <div className="blog-card__meta-count">
                    <ul>
                      <li>
                        <div className="blog-card__meta-reaction">
                          <img
                            src="img/svg/heart.svg"
                            alt="heart"
                            className="svg"
                          />
                          <span className="blog-card__meta-reaction-like">
                            15 K
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="blog-card__meta-doc-wrapper">
                          <img
                            src="img/svg/file-text.svg"
                            alt="file-text"
                            className="svg"
                          />
                          <span className="blog-card__meta-doc">75</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 mb-25">
            <div className="blog-card">
              <div className="blog-card__thumbnail">
                <a href="#">
                  <img src="img/bg3.png" alt="" />
                </a>
              </div>
              <div className="blog-card__details">
                <div className="blog-card__content">
                  <div className="blog-card__title--top">15 March 2021</div>
                  <h4 className="blog-card__title">
                    <a href="#" className="entry-title" rel="bookmark">
                      Multiple Dashboard Design Idea
                    </a>
                  </h4>
                  <p>
                    There are many variations of passages of majority have
                    suffered alteration in some form
                  </p>
                </div>
                <div className="blog-card__meta">
                  <div className="blog-card__meta-profile">
                    <img src="img/alex-suprun.png" alt="" />
                    <span>Machel Bold</span>
                  </div>
                  <div className="blog-card__meta-count">
                    <ul>
                      <li>
                        <div className="blog-card__meta-reaction">
                          <img
                            src="img/svg/heart.svg"
                            alt="heart"
                            className="svg"
                          />
                          <span className="blog-card__meta-reaction-like">
                            15 K
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="blog-card__meta-doc-wrapper">
                          <img
                            src="img/svg/file-text.svg"
                            alt="file-text"
                            className="svg"
                          />
                          <span className="blog-card__meta-doc">75</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 mb-25">
            <div className="blog-card">
              <div className="blog-card__thumbnail">
                <a href="#">
                  <img src="img/bg4.png" alt="" />
                </a>
              </div>
              <div className="blog-card__details">
                <div className="blog-card__content">
                  <div className="blog-card__title--top">15 March 2021</div>
                  <h4 className="blog-card__title">
                    <a href="#" className="entry-title" rel="bookmark">
                      How to Create a Niche Based Dashboard
                    </a>
                  </h4>
                  <p>
                    There are many variations of passages of majority have
                    suffered alteration in some form
                  </p>
                </div>
                <div className="blog-card__meta">
                  <div className="blog-card__meta-profile">
                    <img src="img/alex-suprun.png" alt="" />
                    <span>Machel Bold</span>
                  </div>
                  <div className="blog-card__meta-count">
                    <ul>
                      <li>
                        <div className="blog-card__meta-reaction">
                          <img
                            src="img/svg/heart.svg"
                            alt="heart"
                            className="svg"
                          />
                          <span className="blog-card__meta-reaction-like">
                            15 K
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="blog-card__meta-doc-wrapper">
                          <img
                            src="img/svg/file-text.svg"
                            alt="file-text"
                            className="svg"
                          />
                          <span className="blog-card__meta-doc">75</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex justify-content-end mt-1 mb-50">
              <nav className="dm-page ">
                <ul className="dm-pagination d-flex">
                  <li className="dm-pagination__item">
                    <a
                      href="#"
                      className="dm-pagination__link pagination-control"
                    >
                      <span className="la la-angle-left" />
                    </a>
                    <a href="#" className="dm-pagination__link">
                      <span className="page-number">1</span>
                    </a>
                    <a href="#" className="dm-pagination__link active">
                      <span className="page-number">2</span>
                    </a>
                    <a href="#" className="dm-pagination__link">
                      <span className="page-number">3</span>
                    </a>
                    <a
                      href="#"
                      className="dm-pagination__link pagination-control"
                    >
                      <span className="page-number">...</span>
                    </a>
                    <a href="#" className="dm-pagination__link">
                      <span className="page-number">12</span>
                    </a>
                    <a
                      href="#"
                      className="dm-pagination__link pagination-control"
                    >
                      <span className="la la-angle-right" />
                    </a>
                    <a href="#" className="dm-pagination__option"></a>
                  </li>
                  <li className="dm-pagination__item">
                    <div className="paging-option">
                      <select name="page-number" className="page-selection">
                        <option value={20}>20/page</option>
                        <option value={40}>40/page</option>
                        <option value={60}>60/page</option>
                      </select>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        {/* ends: .row */}
      </div>
    </div>
  </div>
  <footer className="footer-wrapper">
    <div className="footer-wrapper__inside">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="footer-copyright">
              <p>
                <span>© 2023</span>
                <a href="#">Sovware</a>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="footer-menu text-end">
              <ul>
                <li>
                  <a href="#">About</a>
                </li>
                <li>
                  <a href="#">Team</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
              </ul>
            </div>
            {/* ends: .Footer Menu */}
          </div>
        </div>
      </div>
    </div>
  </footer>
</main>
<>
  <div id="overlayer">
    <div className="loader-overlay">
      <div className="dm-spin-dots spin-lg">
        <span className="spin-dot badge-dot dot-primary" />
        <span className="spin-dot badge-dot dot-primary" />
        <span className="spin-dot badge-dot dot-primary" />
        <span className="spin-dot badge-dot dot-primary" />
      </div>
    </div>
  </div>
  <div className="overlay-dark-sidebar" />
  <div className="customizer-overlay" />
  {/* inject:js*/}
  {/* endinject*/}
</>


</>


          
        </ThemeProvider>
     );
}

function App() {
  return (
  <>
      <ProviderConfig />
  </>
  );
}

export default App;
